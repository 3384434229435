<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    autocomplete="off"
  >
    <v-container>
      <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
        Control de temperatura
      </div>

      <v-row >
        <v-col cols="5" sm="3" md="3" lg="2" xl="2">
          <v-menu
            class="mb-n8"
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaFormateada"
                label="Fecha"
                style="height:40px"
                @blur="fecha = parseDate(fechaFormateada)"                
                @change="procesarControlTemperatura"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                readonly
               :rules="[v => !!v || 'La fecha es obligatoria']"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fecha" no-title first-day-of-week = "1" @input="menu1 = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="7" sm="4" md="3" lg="2" xl="2">
          <v-overflow-btn
            class="mb-n8"
            v-model="control"
            :items="itemsControl"
            item-value="ControlNum"
            item-text="ControlNom"
            label="Control"
            outlined
            dense
            return-object
            ref="controlCtrl"
            @change="procesarControlTemperatura"
          ></v-overflow-btn>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="8" xl="8">
          <PerFind class="mb-n7" v-model="empleado"></PerFind>
        </v-col>        
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="itemsMaq"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
            style="overflow: scroll"
            mobile-breakpoint="0"
            class="elevation-1">

            <template v-slot:item.MaqTtraVal="{ item }">
              <v-text-field dense reverse v-model="item.MaqTtraVal" type="number" class="pt-0 pb-1 mb-n7" solo flat ></v-text-field>
            </template>
          </v-data-table>          
        </v-col>
      </v-row>

    <v-row justify="center">
      <v-col cols="1" >
        <v-btn
          block
          color="primary"
          @click="grabar"
          ref="grabarBtn"
          >
          Grabar
        </v-btn>
      </v-col>
    </v-row>

    </v-container>
  </v-form>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  import router from '../router'
  import PerFind from "../components/PerFind.vue";

  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'dirId'])
    },
    data () {
      return {
        loading: true,
        valid: false,
        // fecha: currentDate,
        control: {},
        itemsControl: [],
        headers: [
          { text: 'ELEMENTO', value: 'Maq.MaqNom', class: "col_maq" },
          { text: 'TEMPERATURA', value: 'MaqTtraVal', width: "140" },
          { text: 'VALOR NORMAL', value: 'Maq.MaqTtraRangoTxt', align: "center", width: "220" },
        ],
        fecha: new Date().toISOString().substr(0, 10),
        fechaFormateada: this.formatDate(new Date().toISOString().substr(0, 10)),
        menu1: false,
        empleado: [],
        // searchPer: null,
        // items: [], 
        itemsMaq: []
      }
    },
    components:{
      PerFind
    },
    watch: {
      fecha () {
        this.fechaFormateada = this.formatDate(this.fecha)
        this.procesarControlTemperatura();
      },
    },    
    methods: {
      // ...mapMutations('MensajesRecibidos', ['entidadActualSet']),
      procesarControlTemperatura(){
          var controllerParameters = {
                    Action: "GET_CONTROL_TEMPERATURAS",
                    Fecha: new Date(this.fecha),
                    EmpId: this.empId,
                    ControlNum: this.control.ControlNum,
                    PerId: this.empleado.PerId,
                    DirId: this.dirId
          } ;       
          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
              this.itemsMaq = result.data;

              if (this.itemsMaq[0] != null && this.itemsMaq[0].Per != null)
              {
                // this.items.push(this.itemsMaq[0].Per);
                 this.empleado = this.itemsMaq[0].Per;
//                this.searchPer(this.empleado.PerNom.trim());
              }
              else
              {
                this.empleado = {};
                this.items = [];
                this.$refs.empleadoCtrl.internalSearch = null;
                this.$refs.form.resetValidation();
              }
          });
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      validar(){
        var esValido = this.$refs.form.validate()
        return esValido;
      },
      grabar () {
        if (this.validar())
        {
          var controllerParameters = {
                    Action: "SAVE_CONTROL_TEMPERATURAS",
                    Fecha: new Date(this.fecha),
                    EmpId: this.empId,
                    ControlNum: this.control.ControlNum,
                    PerId: this.empleado.PerId,
                    DirId: this.dirId,
                    MaqTtraList: this.itemsMaq
          } ;       

          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
              .then(result => {
                if (result.status >= 200 && result.status <= 299){
                 alert("Temperaturas grabadas con éxito");
                  router.push('/');
                }
              })
              .catch(error => {
                  alert(error);
              })
        }
      },
      cargarControl(){
        this.itemsControl = [
          { "ControlNum": 1, "ControlNom": "MAÑANA"},
          { "ControlNum": 2, "ControlNom": "TARDE"},
          { "ControlNum": 3, "ControlNom": "CIERRE"}
        ]
      },
  },
  mounted(){
    this.cargarControl();
    this.control = this.itemsControl[0];
    this.procesarControlTemperatura();
  }
}
</script>
<style>
  .col_maq {
    min-width: 230px;
  }
</style>